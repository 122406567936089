import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Header from './Header';
import TopMenu from './TopMenu';
import GlobalStyle from '../style/globalStyle';
import lodashGet from 'lodash/get';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {getContextRoot, getLanguageValue, getApiRoot, googleRecaptcha, getStatusHtml, navigateToPath} from "../common/functions";
import CONSTANTS from '../common/constants';
import HeaderWithLogoBackground from "./HeaderWithLogoBackground";
import MainFooter from "./MainFooter";
import TableComponent from './TableComponent';
import { useParams } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import Loader from './Loader';
import { ACTIONS } from '../redux/actions';
import { CSS_COLORS } from '../common/cssColors';
import SyncIcon from '@material-ui/icons/Sync';

function RegulationsPageBodyComponent(props) {

    const dispatch = useDispatch();
    const routerProps = useParams();
    const [ loaders, setLoaders ] = useState({ init: true });
    const [ errors, setErrors ] = useState({ });
    const [ dataForTable, setDataForTable ] = useState([]);
    const [ groupApplication, setGroupApplication ] = useState({});
    const [ formType, setFormType ] = useState('');
    const configForTable = {
        columns: [
            { key: '#', title: '#' , render: function(row, rowIndex) { return rowIndex + 1;  } },
            { key: 'birthDate', title: getLanguageValue('register-individual.stage7-table-header-date-of-birth'), render: function(item) { return moment(lodashGet(item, formType == 'individual' ? 'stage2Data.birthDate' : 'birthDate')).format(CONSTANTS.DATE_FORMAT_MOMENT); } },
            { key: 'key', title: getLanguageValue('register-individual.stage7-table-header-application-number') },
            { key: 'passportNumber', title: getLanguageValue('register-individual.stage7-table-header-passport-number'), render: function(item) { return lodashGet(item, formType == 'individual' ? 'stage2Data.passportNumber' : 'passportNumber'); } },
            { key: 'status', title: getLanguageValue('register-individual.stage7-table-header-status'), render: function(item) {
                return getStatusHtml(item);
            } },
            { hiddenInMobile: true, key: 'X', title: <>&nbsp;</>, render: function(row) {
                return <div>
                    <Button className={"view-button"} onClick={() => { viewApplication(row); }}><SearchIcon className="view-icon" />{getLanguageValue('register-individual.stage7-table-action-view')}{lodashGet(loaders, row.key + '-view') ? <Loader /> : null}</Button>
                    {lodashGet(errors, row.key + '-view') ? <div className={"error-message-container"}><span className="error-message field-warning-color">{getLanguageValue('register-network-error')}</span></div> : null}
                </div>; }
            },
            { hiddenInMobile: true, key: 'X', title: <>&nbsp;</>, render: function(row) {
                    if (!row.rejectReasons || !Object.keys(row.rejectReasons).length) {
                        return <div />;
                    }
                    return <div>
                        <Button className={"update-rejected-data-button"} onClick={() => { updateRejectedData(row); }}><SyncIcon className="update-rejected-data-icon" />{getLanguageValue('register-individual.stage7-table-action-update-rejected-data')}{lodashGet(loaders, row.key + '-update-rejected-data') ? <Loader /> : null}</Button>
                        {lodashGet(errors, row.key + '-update-rejected-data') ? <div className={"error-message-container"}><span className="error-message field-warning-color">{getLanguageValue('register-network-error')}</span></div> : null}
                    </div>;
                }
            },
            {
                hiddenInMobile: true, key: 'X', title: <>&nbsp;</>, render: function(row) {
                    return <>
                        {lodashGet(row, 'visaFilesIdArray.length') > 0 && <>
                            <div>{getLanguageValue('register-group.stage2-table-action-download')}</div>
                            <div>{getVisaLinks(row)}</div>
                        </>}
                    </>;
                }
            }
        ],
        mobileFooterPerItem: function(row) {
            return <div className={"mobile-actions-buttons-container"}>
                <Button className={"view-button"} onClick={() => { viewApplication(row); }}><SearchIcon className="view-icon" />{getLanguageValue('register-individual.stage7-table-action-view')}{lodashGet(loaders, row.key + '-view') ? <Loader /> : null}</Button>
                {lodashGet(errors, row.key + '-view') ? <div className={"error-message-container"}><span className="error-message field-warning-color">{getLanguageValue('register-network-error')}</span></div> : null}
                {row.rejectReasons != null && Object.keys(row.rejectReasons).length > 0 && <><br/><br/><Button className={"update-rejected-data-button"} onClick={() => { updateRejectedData(row); }}><SyncIcon className="update-rejected-data-icon" />{getLanguageValue('register-individual.stage7-table-action-update-rejected-data')}{lodashGet(loaders, row.key + '-update-rejected-data') ? <Loader /> : null}</Button></>}
                {lodashGet(errors, row.key + '-update-rejected-data') ? <div className={"error-message-container"}><span className="error-message field-warning-color">{getLanguageValue('register-network-error')}</span></div> : null}
                {lodashGet(row, 'visaFilesIdArray.length') > 0 && <>
                    <div>{getLanguageValue('register-group.stage2-table-action-download')}</div>
                    <div>{getVisaLinks(row)}</div>
                </>}
            </div>;
        }
    };

    useEffect(() => {
        (async function() {
            // get visa status
            try {
                let recaptchaToken = await googleRecaptcha();
                let url = getApiRoot() + 'get-visa-status/?languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
                let options = {
                    ...CONSTANTS.POST_DEFAULT_OPTIONS,
                    body: JSON.stringify({
                        key: routerProps.id,
                        recaptchaToken
                    })
                };
                let result = await (await fetch(url, options)).json();
                if (lodashGet(result, 'data.individualApplication')) {
                    // individual application
                    setDataForTable([ lodashGet(result, 'data.individualApplication')] );
                    setFormType('individual');
                }
                else if (lodashGet(result, 'data.groupApplication')) {
                    // group application
                    setDataForTable(lodashGet(result, 'data.linkedIndividualApplications'));
                    setGroupApplication(lodashGet(result, 'data.groupApplication'));
                    setFormType('group');
                }
                else {
                    // invalid result
                    setErrors({...errors, init: getLanguageValue('register-network-error') });
                }
                setLoaders({ ...loaders, init: false });
            }
            catch (err) {
                setLoaders({ ...loaders, init: false });
                // error
                setErrors({...errors, init: getLanguageValue('register-network-error') });
            }
        })();
        window.routeChangeTimestamp = new Date().getTime();
    }, []);

    return <>
        <GlobalStyle/>
        <MainPageBodyStyle>
            <Header className="header-component"/>
            <HeaderWithLogoBackground className="header-background-component" />
            <TopMenu className="top-menu-component" activeMenu={'check-visa-status'} disableAction={formType === 'individual' ? 'retrieve-individual-application' : 'retrieve-group-application'} />
            <div className={"content-container"}>
                <h1>{getLanguageValue('check-visa-status.title')}</h1>
                <br/><br/>
                {loaders.init ? <div className="loader-container"><Loader /> &nbsp; {getLanguageValue('check-visa-status.loading')}</div> : null}
                {errors.init ? <div className="init-error-container">{errors.init}</div> : null}
                {formType === 'group' ? <div className="group-information-container">
                    <h2>{getLanguageValue('check-visa-status.group-details-title')}</h2>
                    <Grid container className={"group-user-information-grid-row"} justify={'space-between'}>
                        <Grid item className={"cell-container first"} md={4}>
                            <span className={"cell-title"}>{getLanguageValue('register-group.stage2-group-id')}</span>
                            <br />
                            <span className={"cell-value"}>{lodashGet(groupApplication, 'key').toUpperCase()}</span>
                        </Grid>
                        <Grid item className={"cell-container"} md={4}>
                            <span className={"cell-title"}>{getLanguageValue('register-group.stage2-first-name')}</span>
                            <br />
                            <span className={"cell-value"}>{lodashGet(groupApplication, 'firstName')}</span>
                        </Grid>
                        <Grid item className={"cell-container"} md={4}>
                            <span className={"cell-title"}>{getLanguageValue('register-group.stage2-family-name')}</span>
                            <br />
                            <span className={"cell-value"}>{lodashGet(groupApplication, 'lastName')}</span>
                        </Grid>
                    </Grid>
                    <hr className={"default-form-hr-style"} />
                    <Grid container className={"group-user-information-grid-row"} justify={'space-between'}>
                        <Grid item className={"cell-container first"} md={4}>
                            <span className={"cell-title"}>{getLanguageValue('register-group.stage2-organization')}</span>
                            <br />
                            <span className={"cell-value"}>{lodashGet(groupApplication, 'organization')}</span>
                        </Grid>
                        <Grid item className={"cell-container"} md={4}>
                            <span className={"cell-title"}>{getLanguageValue('register-group.stage2-date-of-birth')}</span>
                            <br />
                            <span className={"cell-value"}>{moment(lodashGet(groupApplication, 'birthDate')).format(CONSTANTS.DATE_FORMAT_MOMENT)}</span>
                        </Grid>
                        <Grid item className={"cell-container"} md={4}>
                            <span className={"cell-title"}>{getLanguageValue('register-group.stage2-email')}</span>
                            <br />
                            <span className={"cell-value"}>{lodashGet(groupApplication, 'email')}</span>
                        </Grid>
                    </Grid>
                    <hr className={"default-form-hr-style"} />
                </div> : null}
                {formType !== '' && !errors.init ? <h2 className={"applications-details-text"}>{getLanguageValue('check-visa-status.applications-details-title')}</h2> : null}
                {formType !== '' && !errors.init ? <div className="total-forms-text">{getLanguageValue('check-visa-status.total-forms')} {getTotalForms()}</div> : null}
                <TableComponent
                    data={dataForTable}
                    config={configForTable}
                />
                <br/>
                <br/>
            </div>
            <MainFooter className="footer-component" />
        </MainPageBodyStyle>
    </>;

    async function viewApplication(item) {
        if (isLoading()) {
            return;
        }
        setErrors({ ...errors, [item.key + '-view'] : false });
        setLoaders({...loaders, [item.key + '-view']: true });
        let url = getApiRoot() + 'retrieve-information/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
        let recaptchaToken = await googleRecaptcha();
        let bodyObject = (formType === 'individual' ?
            {
                passportNumber: lodashGet(item, 'stage2Data.passportNumber'),
                birthDate: moment(lodashGet(item, 'stage2Data.birthDate')).format('YYYY-MM-DD'),
            }
            : {
                birthDate: moment(lodashGet(item, 'birthDate')).format('YYYY-MM-DD'),
                passportNumber: lodashGet(item, 'passportNumber')
        });
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({
                recaptchaToken,
                key: item.key,
                ...bodyObject
            })
        };
        try {
            let result = await (await fetch(url, options)).json();
            setLoaders({...loaders, [item.key + '-view']: false });
            if (!lodashGet(result, 'data.success')) {
                setErrors({ ...errors, [item.key + '-view']: true });
                return;
            }
            // update state data
            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: [{
                    path: 'registerIndividualStagesData',
                    value: lodashGet(result, 'data.individualApplication')
                }]
            });
            // go to view page
            navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/view');
        }
        catch (err) {
            // error
            setErrors({ ...errors, [item.key + '-view'] : true });
            setLoaders({...loaders, [item.key + '-view']: false });
        }
    }

    async function updateRejectedData(item) {
        if (isLoading()) {
            return;
        }
        setErrors({ ...errors, [item.key + '-update-rejected-data'] : false });
        setLoaders({...loaders, [item.key + '-update-rejected-data']: true });
        let url = getApiRoot() + 'retrieve-information/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
        let recaptchaToken = await googleRecaptcha();
        let bodyObject = (formType === 'individual' ?
            {
                passportNumber: lodashGet(item, 'stage2Data.passportNumber'),
                birthDate: moment(lodashGet(item, 'stage2Data.birthDate')).format('YYYY-MM-DD'),
            }
            : {
                birthDate: moment(lodashGet(item, 'birthDate')).format('YYYY-MM-DD'),
                passportNumber: lodashGet(item, 'passportNumber')
            });
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({
                recaptchaToken,
                key: item.key,
                ...bodyObject
            })
        };
        try {
            let result = await (await fetch(url, options)).json();
            setLoaders({...loaders, [item.key + '-update-rejected-data']: false });
            if (!lodashGet(result, 'data.success')) {
                setErrors({ ...errors, [item.key + '-update-rejected-data']: true });
                return;
            }
            // update state data
            let stateData = { };
            stateData.groupKey = (formType == 'group' ? lodashGet(groupApplication, 'key') : null);
            stateData.formKey = item.key;
            stateData.fields = {}; // currently nothing to fix
            stateData.files = {};
            for (let rejectReason in item.rejectReasons) {
                let matchedFile = (lodashGet(result, 'data.individualApplication.uploadedFiles') || []).find((uploadedItem => uploadedItem.fileType == rejectReason))
                if (matchedFile) {
                    stateData.files[rejectReason] = matchedFile;
                }
                else {
                    const stage = rejectReason.substring(0, 6);
                    const fieldKey = rejectReason.substring(6, 7).toLowerCase() + rejectReason.substring(7);
                    let fieldValue = lodashGet(result, 'data.individualApplication.' + stage + 'Data.' + fieldKey);
                    stateData.fields[rejectReason] = fieldValue;
                }
            }

            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: [
                {
                    path: 'registerIndividualStagesData',
                    value: lodashGet(result, 'data.individualApplication')
                },
                {
                    path: 'rejectedApplicationData',
                    value: stateData
                }]
            });
            // go to update rejected data page
            navigateToPath(dispatch, getContextRoot() + 'update-rejected-data');
        }
        catch (err) {
            // error
            setErrors({ ...errors, [item.key + '-update-rejected-data'] : true });
            setLoaders({...loaders, [item.key + '-update-rejected-data']: false });
        }
    }

    function getVisaLinks(item) {
        let fileIndex = 1;
        if (item.visaFilesIdArray && item.visaFilesIdArray.length) {
            return item.visaFilesIdArray.map((visaFileItem, visaFileItemIndex) => (<React.Fragment key={visaFileItemIndex}><a target={'_blank'} href={getContextRoot() + 'api/download-visa/?type=' + formType + '&key=' + item.key + (formType == 'group' ? ('&groupKey=' + lodashGet(groupApplication, 'key')) : '') + '&fileId=' + visaFileItem}>{getLanguageValue('register-common.download-filename')}{fileIndex++}</a><br /></React.Fragment>));
        }
        return null;
    }

    function getTotalForms() {
        return lodashGet(dataForTable, 'length') || 0;
    }

    function isLoading() {
        for (let key in loaders) {
            if (loaders[key]) {
                return true;
            }
        }
        return false;
    }
}

const MainPageBodyStyle = styled.div`
  .content-container {
    padding-right: 20px;
    padding-left: 20px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      padding: 20px 10%;
    }
  }
  h1 {
    text-align: center;
  }
  .loader-container {
    text-align: center;
  }
  .init-error-container {
    text-align: center;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .group-user-information-grid-row {
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      display: block;
      br, +hr {
        display: none;
      }
      .cell-value {
        html.rtl & {
          float: left;
        }
        html.ltr & {
          float: right;
        }
      }
    }
  }
  .group-information-container {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 25px;
    }
    margin-bottom: 25px;
  }
  .cell-title {
    font-weight: bold;
  }
  .total-forms-text, .applications-details-text {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      width: 80%;
      margin: 0 auto;
    }
  }
  .desktop-details-table {
    margin: 0 auto;
  }
  .mobile-actions-buttons-container {
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .view-button {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      max-width: 250px;
    }
  }
  .error-message-container {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      max-width: 250px;
      margin: 0 auto;
    }
  }
`;


const RegulationsPageBody = connect(
    (state) => ({
        languageKey: state.languageKey // make everything re-render
    }),
    {})(RegulationsPageBodyComponent);

export default RegulationsPageBody;