import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
    getApiRoot,
    getContextRoot,
    getLanguageValue,
    getQueryParam,
    navigateToPath,
    googleRecaptcha,
    convertDateFieldsIntoString
} from "../common/functions";
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import lodashCloneDeep from 'lodash/cloneDeep';
import CONSTANTS from '../common/constants';
import GenericFieldComponent from "./GenericFieldComponent";
import moment from 'moment';
import { ACTIONS } from '../redux/actions';
import Header from './Header';
import TopMenu from './TopMenu';
import MainPageAlerts from './MainPageAlerts';
import HeaderWithLogoBackground from "./HeaderWithLogoBackground";
import GlobalStyle from '../style/globalStyle';
import MainFooter from "./MainFooter";
import Loader from "./Loader";
import {
    useLocation
} from "react-router-dom";
import FloatingWhatsApp from "react-floating-whatsapp";

function RetrieveIndividualApplicationComponent(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const contextRoot = getContextRoot();
    const {
        actions
    } = props;

    const [ autoInputGroup, setAutoInputGroup ] = useState(false);
    const [ autoInput, setAutoInput ] = useState(false);
    const [ loaders, setLoaders ] = useState({});
    const [ fieldsData, setFieldsData ] = useState({
        key: '',
        passportNumber: '',
        birthDate: '',
        ...props.retrieveIndividualApplicationData
    });
    let [ fieldErrors, setFieldErrors ] = useState({});

    const FIELDS = [
        {
            key: 'key',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    disabled: autoInput,
                    className: 'field-component' + (fieldErrors.key ? ' field-error' : ''),
                    error: fieldErrors.key,
                    value: fieldsData.key,
                    inputProps: {
                        name: 'key'
                    },
                    helperText: fieldErrors.key || '',
                    onChange: DEFAULT_ON_CHANGE,
                    label: getLanguageValue('retrieve-individual-information.application-number')
                }
            }
        },
        {
            key: 'passportNumber',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    disabled: autoInput,
                    className: 'field-component' + (fieldErrors.passportNumber ? ' field-error' : ''),
                    error: fieldErrors.passportNumber,
                    value: fieldsData.passportNumber,
                    inputProps: {
                        name: 'passportNumber'
                    },
                    helperText: fieldErrors.passportNumber || '',
                    onChange: DEFAULT_ON_CHANGE,
                    label: getLanguageValue('retrieve-individual-information.passport-number')
                }
            }
        },
        {
            key: 'birthDate',
            validators: [
                {
                    test: (value) => { value = document.querySelector('#birthDate').value; if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    disabled: autoInput,
                    className: 'field-component' + (fieldErrors.birthDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.birthDate,
                    autoOk: true,
                    value: fieldsData.birthDate || null,
                    inputProps: {
                        name: 'birthDate',
                        id: 'birthDate'
                    },
                    helperText: fieldErrors.birthDate || '',
                    onChange: DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'birthDate' }),
                    label: getLanguageValue('retrieve-group-information.date-of-birth')
                }
            }
        }
    ];

    useEffect(() => {
        //let searchString = window.location.search;
        if (getQueryParam('autoinputKey') && getQueryParam('autoinputPassportNumber') && getQueryParam('autoinputBirthDate')) {
            // try to start automatically
            setFieldsData({
                key: getQueryParam('autoinputKey'),
                passportNumber: getQueryParam('autoinputPassportNumber'),
                birthDate: moment(getQueryParam('autoinputBirthDate')).toDate(),
            });
            setAutoInput(true);
        }
        window.routeChangeTimestamp = new Date().getTime();
    }, []);

    useEffect(() => {
        if (autoInput) {
            retrieve();
        }
    }, [ autoInput ]);

    useEffect(() => {
        if (autoInputGroup) {
            retrieveGroup();
        }
    }, [ autoInputGroup ]);


    function DEFAULT_EMPTY_VALUE_VALIDATOR(value) {
        if (!value) {
            return false;
        }
        return true;
    }

    function DEFAULT_STRING_VALUE_VALIDATOR(value) {
        if (!/^([\u0590-\u05FF]+)|(\s)*$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_ON_CHANGE(e) {
        setFieldsData({...fieldsData, [e.target.name] : e.target.value });
        setFieldErrors({ ...fieldErrors, [e.target.name] : null });
    }

    function DEFAULT_DATE_ON_CHANGE(date) {
        if (moment(date, true).isValid()) {
            lodashSet(fieldsData, this.fieldName, date);
            setFieldsData({ ...fieldsData });
            setFieldErrors({ ...fieldErrors, [this.fieldName] : null });
        }
        else {
            lodashSet(fieldsData, this.fieldName, null);
            setFieldsData({...fieldsData });
        }
    }

    if (props.isRetrieveLinkedToGroup && !props.groupKey) {
        // should not be here - go home
        navigateToPath(dispatch, getContextRoot());
        return null;
    }

    return <RetrieveIndividualApplicationStyle>
        <GlobalStyle/>
        {CONSTANTS.WHATSAPP_ICON_ENABLED && <FloatingWhatsApp
            className={'floating-whatsapp'}
            phoneNumber={CONSTANTS.SUPPORT_PHONE_NUMBER}
            accountName={getLanguageValue('whatsapp.header-name')}
            statusMessage={getLanguageValue('whatsapp.status-message')}
            chatMessage={getLanguageValue('whatsapp.initial-message')}
        />}
        <Header className="header-component"/>
        <HeaderWithLogoBackground className="header-background-component" />
        <MainPageAlerts />
        <TopMenu className="top-menu-component" activeMenu={isContinueProcessPath() ? 'continue-visa-process' : 'check-visa-status'} disableAction={'retrieve-individual-application' + (isContinueProcessPath() ? '-continue-process' : '')}/>
        <div className="content-body-container">
            <h1>{props.isRetrieveLinkedToGroup ? getLanguageValue('retrieve-individual-for-group-information-title') : getLanguageValue('retrieve-individual-information-title')} {props.isRetrieveLinkedToGroup ? <>- {props.groupKey}</> : null}</h1>
            <div>{getLanguageValue('retrieve-individual-information-explanation')}</div>
            <div className="bold-text field-warning-color">{getLanguageValue('retrieve-individual-information-all-fields-required')}</div>
            <Grid container className={"fields-container"}>
                {FIELDS.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={6}>
                    <GenericFieldComponent {...fieldItem.props} />
                </Grid>))}
            </Grid>
            <div className={"buttons-container"}>
                {!autoInput && <Button className="common-style-primary-button retrieve-individual-application-button" onClick={retrieve}>{getLanguageValue('retrieve-individual-information.retrieve')} {loaders.retrieve && <Loader className={"loader-component"} />}</Button>}
                {autoInput && !fieldErrors.response && <Loader className={"autoinput-loader-component"} />}
            </div>
            {fieldErrors.response ? <div className={"error-response-container bold-text field-warning-color"} role={"alert"}>
                {fieldErrors.response}
            </div> : null}
            {fieldErrors.response == getLanguageValue('retrieve-individual-information.retrieve-result-belongs-to-group') ? <Button className="common-style-primary-button retrieve-group-application-button" onClick={retrieveGroup}>{getLanguageValue('retrieve-individual-information.retrieve-group')} {loaders.retrieveLinkedGroup && <Loader className={"loader-component"} />}</Button> : null }
            {fieldErrors.retrieveLinkedGroupResponse ? <div className={"error-response-container bold-text field-warning-color"} role={"alert"}>
                {fieldErrors.retrieveLinkedGroupResponse}
            </div> : null}
        </div>
        <MainFooter className="footer-component" />
    </RetrieveIndividualApplicationStyle>;

    async function retrieve() {
        if (loaders.retrieve) {
            return;
        }
        let validateResult = validate({ setErrors: true });
        if (validateResult.length) {
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        let url = getApiRoot() + 'retrieve-information/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
        let reduxPath = 'apiData.retrieve.individual';
        let duplicateFieldsData = lodashCloneDeep(fieldsData);
        convertDateFieldsIntoString(duplicateFieldsData, 'YYYY-MM-DD');
        if (props.isRetrieveLinkedToGroup) {
            duplicateFieldsData.individualKey = duplicateFieldsData.key;
            delete duplicateFieldsData.key;
            duplicateFieldsData.groupKey = props.groupKey;
            url = getApiRoot() + 'attach-individual-application-to-group/?languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
            reduxPath = 'apiData.attachIndividual';
        }
        let recaptchaToken = await googleRecaptcha();
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({
                ...duplicateFieldsData,
                recaptchaToken
            })
        };
        try {
            setFieldErrors({ ...fieldErrors, response: null, retrieveLinkedGroupResponse: false });
            setLoaders({ ...loaders, retrieve: true });
            let result = await (await fetch(url, options)).json();
            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: [{
                    path: reduxPath,
                    value: lodashGet(result, 'data')
                }]
            });
            if (props.isRetrieveLinkedToGroup) {
                // retrieve individual for group application
                if (!lodashGet(result, 'data.success')) {
                    // error
                    if (lodashGet(result, 'err') == 'already attached') {
                        if (autoInput) {
                            setAutoInputGroup(true);
                            return;
                        }
                        setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-individual-information.retrieve-result-already-belongs-to-group') });
                    }
                    else if (lodashGet(result, 'err') == 'individual application expired') {
                        setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-individual-information.retrieve-result-expired') });
                    }
                    else if (lodashGet(result, 'err') == 'mismatching field(s)') {
                        setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-individual-information.retrieve-result-mismatching-fields') });
                    }
                    else {
                        setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-individual-information.retrieve-result-no-such-application') });
                    }
                }
                else {
                    // link success
                    dispatch({
                        type: ACTIONS.GENERIC_SET_VALUE,
                        payload: [{
                            path: 'globalControls.isRetrieveLinkedToGroup',
                            value: false
                        }]
                    });
                    // rebuild linked list
                    let newLinkedApplicationsData = [];
                    for (let item of props.linkedApplicationsData) {
                        newLinkedApplicationsData.push(item);
                    }
                    const birthDateValue = lodashGet(result, 'data.individualApplication.stage2Data.birthDate');
                    newLinkedApplicationsData.push({
                        name: lodashGet(result, 'data.individualApplication.stage2Data.firstName') + ' ' + lodashGet(result, 'data.individualApplication.stage2Data.lastName'),
                        birthDate: typeof(birthDateValue) == 'string' ? moment(lodashGet(result, 'data.individualApplication.stage2Data.birthDate')).toDate() : birthDateValue,
                        key: lodashGet(result, 'data.individualApplication.key'),
                        passportNumber: lodashGet(result, 'data.individualApplication.stage2Data.passportNumber'),
                        status: lodashGet(result, 'data.individualApplication.status'),
                        visaType: lodashGet(result, 'data.individualApplication.stage2Data.visaType'),
                        visaExpedited: lodashGet(result, 'data.individualApplication.stage2Data.visaExpeditedFlag'),
                        rejectReasons: lodashGet(result, 'data.individualApplication.rejectReasons'),
                        visaDeliveryType: lodashGet(props, 'data.individualApplication.deliveryType'),
                        visaDeliveryDestination: lodashGet(props, 'data.individualApplication.deliveryDestination'),
                    });
                    dispatch({
                        type: ACTIONS.GENERIC_SET_VALUE,
                        payload: [{
                            path: 'registerGroupStagesData.stage2Data.linkedIndividualApplications',
                            value: newLinkedApplicationsData
                        }]
                    });
                    navigateToPath(dispatch, getContextRoot() + 'register-visa-for-group/stage2');
                }
            }
            else {
                // retrieve individual and go to it's active status page
                if (!lodashGet(result, 'data.success')) {
                    // error
                    if (lodashGet(result, 'err') == 'mismatching field(s)') {
                        setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-individual-information.retrieve-result-mismatching-fields') });
                    }
                    else {
                        setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-individual-information.retrieve-result-no-such-application') });
                    }
                }
                else {
                    // organize data and save
                    // let stage1Data = { ...lodashGet(result, 'data.groupApplication') };
                    // stage1Data = {
                    //     firstName: stage1Data.firstName,
                    //     lastName: stage1Data.lastName,
                    //     organization: stage1Data.organization,
                    //     birthDate: stage1Data.birthDate,
                    //     email: stage1Data.email,
                    //     emailConfirm: stage1Data.email
                    // };
                    let stagesData = JSON.parse(JSON.stringify(lodashGet(result, 'data.individualApplication')));
                    if (stagesData.isExpired) {
                        setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-individual-information.retrieve-result-expired') });
                        setLoaders({ ...loaders, retrieve: false });
                        return;
                    }
                    stagesData.stage2UploadedPassport = true;
                    for (let stageKey in stagesData) {
                        if (stageKey.startsWith('stage') && stageKey.endsWith('Data')) {
                            let stageData = stagesData[stageKey];
                            for (let fieldKey in stageData) {
                                if (fieldKey.endsWith('Date') && /^\d{4}-\d{2}-\d{2}$/g.test(stageData[fieldKey])) {
                                    // update date field
                                    lodashSet(stagesData, stageKey + '.' + fieldKey, moment(stageData[fieldKey]).toDate());
                                }
                            }
                        }
                    }
                    if (lodashGet(stagesData, 'stage4Data.contactCity')) {
                        stagesData.stage4UploadedFlightTicket = true;
                        stagesData.stage4UploadedHotelPaymentConfirmation = true;
                    }

                    // if have a linked group application - notify the user that there's a group application instead of showing just the individual application
                    if (lodashGet(result, 'data.linkedGroupApplication')) {
                        if (autoInput) {
                            setAutoInputGroup(true);
                            return;
                        }
                        setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-individual-information.retrieve-result-belongs-to-group') });
                        setLoaders({ ...loaders, retrieve: false });
                        return;
                    }

                    // reached here - update state and show relevant stage
                    dispatch({
                        type: ACTIONS.GENERIC_SET_VALUE,
                        payload: [{
                            path: 'registerIndividualStagesData',
                            value: stagesData
                        }]
                    });
                    // go to page
                    if (lodashGet(result, 'data.individualApplication.isPaid')) {
                        navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/status');
                    }
                    else if (!lodashGet(stagesData, 'stage3Data.maritalStatus')) {
                        navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage2');
                    }
                    // else if (!lodashGet(stagesData, 'stage4Data.contactCity')) {
                    //     navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage3');
                    // }
                    else if (!lodashGet(stagesData, 'stage5Data.questionsAndAnswers')) {
                        navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage5');
                    }
                    else {
                        navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual/stage6');
                    }
                }
            }
        }
        catch (err) {
            // nothing to do
            setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-individual-information.retrieve-result-network-error') });
        }
        setLoaders({ ...loaders, retrieve: false });
    }

    async function retrieveGroup() {

        let linkedGroupKey = lodashGet(props.apiDataRetrieveIndividual, 'linkedGroupApplication.key');
        setLoaders({ ...loaders, retrieveLinkedGroup: true });
        setFieldErrors({ ...fieldErrors, retrieveLinkedGroupResponse: false });
        try {
            let recaptchaToken = await googleRecaptcha();
            let options = {
                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                body: JSON.stringify({
                    recaptchaToken,
                    key: linkedGroupKey,
                    email: lodashGet(props.apiDataRetrieveIndividual, 'linkedGroupApplication.email'),
                    firstName: lodashGet(props.apiDataRetrieveIndividual, 'linkedGroupApplication.firstName'),
                    lastName: lodashGet(props.apiDataRetrieveIndividual, 'linkedGroupApplication.lastName'),
                    birthDate: moment(lodashGet(props.apiDataRetrieveIndividual, 'linkedGroupApplication.birthDate')).format('YYYY-MM-DD'),
                })
            };
            let url = getApiRoot() + 'retrieve-information/?type=group&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
            let result = await (await fetch(url, options)).json();
            setTimeout(() => {
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [{
                        path: 'apiData.retrieve.group',
                        value: lodashGet(result, 'data')
                    }]
                });
            }, 10);
            if (!lodashGet(result, 'data.success')) {
                setFieldErrors({ ...fieldErrors, retrieveLinkedGroupResponse: getLanguageValue('retrieve-individual-information.retrieve-result-network-error') });
            }
            else {
                // setup data
                let stateData = {
                    createDate: lodashGet(result, 'data.groupApplication.createDate'),
                    isPaid: lodashGet(result, 'data.groupApplication.isPaid'),
                    status: lodashGet(result, 'data.groupApplication.status'),
                    key: lodashGet(result, 'data.groupApplication.key'),
                    stage1Data: JSON.parse(JSON.stringify(lodashGet(result, 'data.groupApplication'))),
                    stage2Data: {
                        linkedIndividualApplications: JSON.parse(JSON.stringify(lodashGet(result, 'data.linkedIndividualApplications')))
                    }
                };
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [{
                        path: 'registerGroupStagesData',
                        value: stateData
                    }]
                });
                // go to group status page
                navigateToPath(dispatch, getContextRoot() + 'register-visa-for-group/' + (stateData.isPaid ? 'status' : 'stage2'));
            }
        }
        catch (err) {
            setFieldErrors({ ...fieldErrors, retrieveLinkedGroupResponse: getLanguageValue('retrieve-individual-information.retrieve-result-network-error') });
        }
        setLoaders({ ...loaders, retrieveLinkedGroup: false });

        //console.log('that', props.apiDataRetrieveIndividual, lodashGet(props.apiDataRetrieveIndividual, 'linkedGroupApplication.key'));
    }

    function validate(config) {
        let errors = [];
        if (lodashGet(config, 'setErrors')) {
            fieldErrors = {};
        }
        for (let fieldItem of FIELDS) {
            let fieldValue = fieldsData[fieldItem.key];
            let fieldValidators = fieldItem.validators;
            if (fieldValidators) {
                for (let fieldValidatorItem of fieldValidators) {
                    if (!fieldValidatorItem.test(fieldValue)) {
                        errors.push(fieldItem.key);
                        if (lodashGet(config, 'setErrors')) {
                            fieldErrors[fieldItem.key] = fieldValidatorItem.errorMessage;
                        }
                        break;
                    }
                }
            }
        }
        if (lodashGet(config, 'setErrors')) {
            setFieldErrors(fieldErrors);
        }

        return errors;
    }

    function isContinueProcessPath() {
        if (location.pathname.indexOf('retrieve-individual-application/continue-process') > -1) {
            return true;
        }
        return false;
    }
}

const RetrieveIndividualApplicationStyle = styled.div`
  .content-body-container {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 25px;
  }
  .fields-container {
    margin-top: 25px;
  }
  .single-field-container {
    width: 100%;
    margin-bottom: 25px;
  }
  .field-component {
    width: calc(100% - 20px);
    html.rtl & {
      margin-left: 20px;
    }
    html.ltr & {
      margin-right: 20px;
    }
  }
  .error-response-container {
    margin-top: 10px;
  }
  .loader-component {
    color: white;
  }
`;

const RetrieveIndividualApplication = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        retrieveIndividualApplicationData: state.retrieveIndividualApplication || {},
        isRetrieveLinkedToGroup: lodashGet(state, 'globalControls.isRetrieveLinkedToGroup') || getQueryParam('isRetrieveLinkedToGroup') || false,
        apiDataRetrieveIndividual: lodashGet(state, 'apiData.retrieve.individual'),
        linkedApplicationsData: lodashGet(state, 'registerGroupStagesData.stage2Data.linkedIndividualApplications') || [],
        groupKey: lodashGet(state, 'registerGroupStagesData.key')
    }),
    {})(RetrieveIndividualApplicationComponent);

export default RetrieveIndividualApplication;
